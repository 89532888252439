// version 0.0.0
import { defineStore, storeToRefs } from 'pinia'
import { useHelpers, useServices } from '@/lib/services'
import type {
  WorkSpaceCurrent as WSC,
  WorkSpaceUser,
  Team,
  UserWorkSpaceData,
  CreateWorkspace,
  operationHours as OperationHours
} from '@/lib/api'
import { Languages, WorkspaceState, type WorkspaceStatus } from '@/lib/api'
import type { PermissionPeopleItem } from '@/modules/Permissions/components/interfaces'
import { useAuthenticationStore } from '../../../stores/authentication/useAuthenticationStore'
import { createDefaultWorkspaces } from '@/modules/Workspace/helpers/CreateWorkspace'
import type { Location } from '@/modules/Camera/interface'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { useCustomSettingStore } from '@/stores/custom-setting/useCustomSettingStore'
import { useWorkspaceStatusStore } from './useWorkspaceStatusStore'
import { useEventBus } from '@/utils/event-bus/EventBus'
import { ConvertError } from '@/utils/helpers'

export function workspaceStatusToNumber(status: WorkspaceState) {
  switch (status) {
    case WorkspaceState.pending:
      return 1
    default:
      return 2
  }
}

interface ApplicationStoreState {
  currentWorkspace: WSC | null
  workspaces: UserWorkSpaceData[]
  lockState: boolean
  users: WorkSpaceUser[]
  teamList: Team[] | null
  resource: any
  AllUsersWs: PermissionPeopleItem[]
  currentWorkspaceAvatar: string
  location: Location
  isHoursOpen: boolean
  disableOperationsUpdate: boolean
  operationsString: string
}
export const useWorkspaceStore = defineStore('WorkspaceSetting', {
  state: (): ApplicationStoreState => ({
    lockState: true,
    currentWorkspace: null,
    users: [],
    workspaces: [],
    teamList: null,
    resource: {},
    AllUsersWs: [],
    currentWorkspaceAvatar: '',
    location: {
      longitude: -74.0072955,
      latitude: 40.7094756,
      fullAddress: ''
    },
    disableOperationsUpdate: true,
    operationsString: '',
    isHoursOpen: false
  }),

  getters: {
    workspaceById() {
      const workspaces = this.workspaces
      return (id: string) => workspaces.find((workspace) => workspace.id === id)
    },
    workspacesList() {
      const workspaceStatus: WorkspaceStatus[] = useWorkspaceStatusStore().statusList
      const workspaces: Array<[UserWorkSpaceData, WorkspaceStatus | undefined]> = [
        ...this.workspaces
      ].map((w) => [w, workspaceStatus.find((ww) => ww.id === w.id)])
      workspaces.sort(([aW, aS], [bW, bS]) => {
        if (aS && bS) {
          return (
            Number(aS.lockForUser) - Number(bS.lockForUser) ||
            Number(bS.activePayment) - Number(aS.activePayment) ||
            workspaceStatusToNumber(aW.status) - workspaceStatusToNumber(bW.status) ||
            new Date(bW.createdAt).getTime() - new Date(aW.createdAt).getTime()
          )
        } else return 0
      })
      return workspaces.map(([w]) => w)
    }
  },

  actions: {
    setWorkspace(workspace: WSC) {
      this.currentWorkspace = workspace
      this.operationsString = JSON.stringify(
        workspace.operationHours.sort((a, b) => a.dayName - b.dayName)
      )
      this.disableOperationsUpdate = true
    },
    setHoursState(state: boolean) {
      this.isHoursOpen = state
    },
    async updateCurrentWorkspace(data: CreateWorkspace) {
      const workspace = await useServices().workspaceManager.workspace.update(
        String(this.currentWorkspace?.id),
        data
      )
      this.setWorkspace(workspace)
      return workspace
    },
    async createNewWorkspace() {
      const service = useServices()
      const helpers = useHelpers()
      await this.getWorkspaces(true)
      const userWorkspaceCount: number = this.workspaces.length
      const workspace = await service.workspaceManager.workspace.create({
        name: 'My Space ' + helpers.workspaceHelper.integerToRoman(userWorkspaceCount),
        description: "Connect Any Device, Secure Any Space. Because It's Powerful to Take Control.",
        language: Languages.english,
        welcomeMessage: 'welcome'
      })
      this.setWorkspace(workspace)
      await service.workspaceManager.user.switchWorkspace(String(workspace.id))
      useAuthenticationStore().storage.setItem('workspaceId', String(workspace.id))
      return workspace
    },
    async getCurrentWorkspace() {
      const service = useServices()
      const workspace = await service.workspaceManager.workspace.current()
      this.setWorkspace(workspace)
    },
    async getWorkspaces(force: boolean = false, avatarGet: boolean = true) {
      try {
        if (this.workspaces.length === 0 || force) {
          const workspaces = await useServices().workspaceManager.user.workspaces()
          await useWorkspaceStatusStore().fetchStatuses()
          const { currentUser } = storeToRefs(useAuthenticationStore())
          if (!currentUser.value) await useAuthenticationStore().getCurrentUser()
          if (avatarGet) {
            this.workspaces = await this.getAvatarWs(workspaces)
          } else {
            this.workspaces = workspaces
          }
          await useWorkspaceStatusStore().fetchStatuses()
          if (workspaces.length === 0) {
            await createDefaultWorkspaces()
          }
        }
        return this.workspaces
      } catch (e) {
        useEventBus().emit('toast.add', {
          severity: 'error',
          summary: 'Workspaces statuses',
          detail: ConvertError(e),
          life: 3000
        })
      }
    },

    async getWorkspaceResource() {
      if (this.currentWorkspace) {
        try {
          const response = await useServices().permissionManager.resource.findResource(
            this.currentWorkspace?.id.toString(),
            'w'
          )
          this.resource = response
        } catch (e) {
          console.log(e)
        }
      }
    },
    async loadCurrentWorkspaceAvatar(): Promise<void> {
      if (this.currentWorkspaceAvatar === '') {
        // eslint-disable-next-line no-async-promise-executor
        const image = await useServices().workspaceManager.workspace.avatarGet(
          String(this.currentWorkspace?.id)
        )
        try {
          if (image.data.size > 0) {
            const reader = new FileReader()
            reader.addEventListener(
              'load',
              () => {
                this.currentWorkspaceAvatar = reader.result as string
              },
              false
            )
            reader.readAsDataURL(image.data)
          }
        } catch (e) {
          //
        }
      }
    },
    async getAvatarWs(workspaces) {
      const promises = workspaces.map(async (eachWs) => {
        if (this.currentWorkspace && eachWs.id === this.currentWorkspace.id) {
          return { ...eachWs, logo: this.currentWorkspaceAvatar }
        }
        const logo = await useHelpers().workspaceHelper.getWorkspaceAvatar(String(eachWs?.id))
        if (logo) {
          if (this.currentWorkspace && eachWs.id === this.currentWorkspace.id) {
            this.currentWorkspaceAvatar = logo as string
          }
          return { ...eachWs, logo: logo }
        } else {
          return eachWs
        }
      })
      return Promise.all(promises)
    },
    async loadWorkspaceAddress() {
      const stripeWorkspace = await usePaymentStore().loadCurrentStripeWorkspace()
      if (stripeWorkspace.data.address) {
        this.location.fullAddress = stripeWorkspace.data.address.line1
      }
      await useCustomSettingStore().getWorkspaceListCustom()
      const location = useCustomSettingStore().workspaceSettingList.find(
        (item) => item.key === 'workspaceLocation'
      )
      if (location) {
        this.location.longitude = location.value.longitude
        this.location.latitude = location.value.latitude
      }
    },
    updateCurrentOperationHours(newOperationHours: Array<OperationHours>) {
      if (this.currentWorkspace) {
        const newListString = JSON.stringify(
          newOperationHours.sort((a, b) => a.dayName - b.dayName)
        )
        const oldListString = this.operationsString
        if (newListString !== oldListString) {
          this.disableOperationsUpdate = false
        } else {
          this.disableOperationsUpdate = true
        }
        this.currentWorkspace.operationHours = newOperationHours
      }
    },

    setCurrentWorkspaceAvatar(value: string) {
      this.currentWorkspaceAvatar = value
    },

    async removeCurrentWorkspace() {
      await useServices().workspaceManager.workspace.removeCurrentWorkspace()
    },

    async removeAvatar() {
      await useServices().workspaceManager.workspace.avatarRemove()
    }
  }
})
